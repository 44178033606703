<template>
     <v-card
        elevation="10"
        class="mx-auto"
    >
        <v-toolbar
            dark
            cards                
            flat
            outlined
            color="primary"
        >
            <v-card-title class="text-h6 font-weight-regular">
            {{cmpOptions.title}}
            </v-card-title>
            <v-spacer> </v-spacer>
  
            <v-btn 
                @click= "close($event)"
                icon>
                <v-icon>mdi-close-thick</v-icon>
            </v-btn>
 
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="stepStates.Base.state.valid"
                        step="steps.Base"
                        @click="goToStep(steps.Base)"
                    >
                        Allgemein
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="true"
                        step="steps.Geo"
                        @click="goToStep(steps.Geo)"
                    >
                        Geo
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        v-if="this.editField.cultivation_species_id == 1000 "
                        :complete="stepStates.Wine.state.valid"
                        :step="steps.Wine"
                        @click="goToStep(steps.Wine)"
                    >
                        Meta
                    </v-stepper-step>
                    <v-divider v-if="this.editField.cultivation_species_id == 1000 "></v-divider>
                    <v-stepper-step
                        v-if="editField.isLeased"
                        :complete="stepStates.Leased.state.valid"
                        :step="steps.Leased"
                        @click="goToStep(steps.Leased)"
                    >
                        Pacht
                    </v-stepper-step>
                    <v-divider  v-if="editField.isLeased"></v-divider>
                    <v-stepper-step
                        :step="steps.Check"
                        @click="goToStep(steps.Check)"
                    >
                        Prüfen
                    </v-stepper-step>

                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <InputMaskBase 
                            :item="editField" 
                            :state="stepStates.Base.state" 
                            :isEdit="cmpOptions.isEdit" 
                            :isVisible="isVisible" 
                            @cultivationspecieschanged="onCultivationSpeciesChanged"
                            @isleasedchanged="onIsLeasedChanged">
                        
                        </InputMaskBase>
                        <v-container>
                            <v-row>    
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="!stepStates.Base.state.valid || !editField.name"
                                    color="primary"
                                    @click="stepForward"                                >
                                Weiter
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <InputMaskGeoData :item="editField" :state="stepStates.Geo.state"></InputMaskGeoData>
                        <v-container>
                            <v-row>
                                <v-btn
                                    @click="stepBackwards" 
                                    text
                                >
                                    Zurück
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                color="primary"
                                @click="stepForward"
                                >
                                Weiter
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-stepper-content>
                    <v-stepper-content :step="steps.Wine">
                        <InputMaskMetaWine 
                            v-if="this.editField.cultivation_species_id == 1000" 
                            :item="editField.meta.wine" 
                            :state="stepStates.Wine.state" 
                            :isEdit="cmpOptions.isEdit" 
                            :isVisible="isVisible">
                        </InputMaskMetaWine>
                         <v-container>
                            <v-row> 
                                <v-btn
                                    @click="stepBackwards" 
                                    text
                                >
                                    Zurück
                                </v-btn>   
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="!stepStates.Wine.state.valid"
                                    color="primary"
                                    @click="stepForward"
                                >
                                Weiter
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-stepper-content>
                    <v-stepper-content :step="steps.Leased">
                        <InputMaskIsLeased 
                            v-if="this.editField.isLeased" 
                            :item="editField.leased" 
                            :state="stepStates.Leased.state" 
                            :isEdit="cmpOptions.isEdit" 
                            :isVisible="isVisible">
                        </InputMaskIsLeased>
                         <v-container>
                            <v-row> 
                                <v-btn
                                    @click="stepBackwards" 
                                    text
                                >
                                    Zurück
                                </v-btn>   
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="!stepStates.Leased.state.valid"
                                    color="primary"
                                    @click="stepForward"
                                >
                                Weiter
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-stepper-content>                    
                    <v-stepper-content :step="steps.Check">
                        <InputMaskCheckData :item="editField" ></InputMaskCheckData>  
                        <v-container>
                            <v-row>
                                <v-btn
                                    @click="stepBackwards" 
                                    text
                                >
                                    Zurück
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    @click="submit"
                                >
                                {{captionBtnSubmit}}
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-container>
    </v-card>
</template>

<script>
    // import { gmapApi } from 'vue2-google-maps'
    // import InputMaskMetaWine from './tmpl/fieldInputMaskMetaWine.vue'
    // import { getGoogleMapsAPI  } from 'gmap-vue';

    export default {

        name: "addEditField",
        components:{
            InputMaskMetaWine: () => import("./InputMasks/fieldMetaWine.vue"),
            InputMaskBase: () => import("./InputMasks/fieldBase.vue"),
            InputMaskGeoData: () => import("./InputMasks/fieldGeoData.vue"),
            InputMaskCheckData: () => import("./InputMasks/fieldCheckData.vue"),
            InputMaskIsLeased: () => import("./InputMasks/fieldIsLeased.vue"),
             
        },

        props:{
            field: {
                required: false,
                type: Object,
                default: () => undefined
            },
            options:{
                required: false,
                type: Object
            },
            isVisible:{                
                required: true,
                default: false,
                type: Boolean
            }
        },

        data() {
            return {
                defaultOptions:{
                    title: 'Title',
                    isEdit: true
                },
                defaultField: {
                    id: -1,
                    name: '',
                    area_ha: '',
                    area_ha_calculated: true,
                    cultivation_species: '',
                    isLeased: false,
                    polygon:[] ,                
                    meta:{
                        wine:{
                            type:null,
                            date_of_cultivation: '',
                            row_distance: null,
                            grapevine_distance: null
                        },
                        
                    },
                    leased:{
                        contact_id: null,
                        payday: '',
                        amount: '',
                    }
                },
                editField: {
                    id: 1,
                    name: null,
                    area_ha: null,
                    area_ha_calculated: true,
                    cultivation_species: null,
                    isLeased: null,
                    polygon:[],                    
                    meta:{
                        wine:{
                            type:""
                        },
  
                    },
                    leased:{
                        contact_id: null,
                        payday: '',
                        amount: '',
                    }
                }, 
                
                steps:{
                    Base:1,                    
                    Geo:2,
                    Wine:3,
                    Leased:4,
                    Check:5
                },

                stepStates:{
                    Base:{state:{valid: false}},
                    Wine:{state:{valid: false}},
                    Geo:{state:{valid: false}},
                    Leased:{state:{valid: false}}
                },

 
                isLoading: false,
                message: '',
                errMessage: '',
                e1: 1,
 
 
 
            }
        },

        computed:{            
            // google: getGoogleMapsAPI,
 

            cmpOptions(){

                const res = {
                    ...this.defaultOptions,
                    ...this.options                    
                }
                console.debug('cmpOptions', res);
                return res;
            },

 
            captionBtnSubmit(){
                return this.cmpOptions.isEdit ? 'Ändern' : 'Speichern';
            },

            // isWine(){
            //     console.debug("this.editField.cultivation_species_id == 1000.cultivation_species_id == 1000", )
            //     return this.cultivation_species_id == 1000 
            // },

            cultivation_species_id(){
                console.debug("cultivation_species_id", this.editField.cultivation_species_id)
                return this.editField.cultivation_species_id
            },

            step2Compl(){
                return this.editField.polygon ? this.editField.polygon.length > 0 : false;
            },


        },

        methods:{ 


            close(event){
                this.editField = this.defaultField;
                console.debug('close', event)
                //this.$emit('close', event)  
                this.$emit('update:isVisible', false)        
            },

            submit(){
                console.debug('submit', this.editField)
                const compid = this.$store.state.app.actCompany.meta.id

                const apicmd = this.cmpOptions.isEdit ? "putApiSetStore" : "postApiSetStore"
                this.$store.dispatch('app/'+ apicmd, {path:"api/company/field", param:{store:"fields/items", compid:compid, data:this.editField}})
                .then( ()=>{ 
                    return this.$store.dispatch("app/getApiSetStore", {path:"api/company/vinicultures/types", param:{store:"availableVinicultureTypes", id:compid}})
                }).then((response)=>{ 

                    console.log("submit:", response); 
                    this.$emit('close')  

                })
                .catch((error)=>{
                    console.error(error);
                })
 
           
            },

            onCultivationSpeciesChanged(id){
                console.debug('onCultivationSpeciesChanged', id)
                this.setSteps()
            },

            onIsLeasedChanged(val){
                console.debug('onIsLeasedChanged', val)
                this.setSteps()
            },

            stepBackwards(){
                this.errMessage = '';
                if(this.e1 <= this.steps.Base)
                    return;
                
                this.e1 = this.e1 - 1
            },

            stepForward(){
                this.errMessage = '';
                if(this.e1 >=  this.steps.Check)
                    return;
                    
                this.e1 = this.e1 + 1
                // console.debug('debug', this.editField)
            },

            goToStep(step){
                console.debug('goToStep', step)
                if(step > 1 && !this.stepStates.Base.state.valid)
                    return;
                this.errMessage = '';
                this.e1 = step
            },

            goToNextStep(){
                this.errMessage = '';
                this.e1 += 1
            },            

            setSteps(){
                
                this.steps.Base = 1
                this.steps.Geo = 2
                this.steps.Wine = this.editField.cultivation_species_id == 1000 ? 3 : 10
                this.steps.Leased =  this.editField.isLeased ? this.editField.cultivation_species_id == 1000 ? 4 : 3 : 10;
                const minStep = Math.min(this.steps.Wine, this.steps.Leased)
                const maxStep = Math.max(this.steps.Wine, this.steps.Leased)
                 this.steps.Check =  minStep == 10 ?  3 : maxStep == 10 ? minStep + 1 : maxStep + 1; 
                console.debug('setSteps', this.steps)

            },
             

            load(){
                console.debug("load")
                this.e1 = 1;
                
                if(this.cmpOptions.isEdit){                   

                    if(!this.field.meta || this.field.meta == {})
                        this.field.meta = this.defaultField.meta

                    if(!this.field.leased || this.field.leased == {})
                        this.field.leased = this.defaultField.leased   

                    this.editField = Object.assign(JSON.parse(JSON.stringify(this.defaultField)), JSON.parse(JSON.stringify(this.field)))

                    this.selectCultivationSpecies = this.field.cultivation_species_id;
                  
                    console.debug('isVisible', this.editField);
                }
                else{
                    console.debug('isVisible add', this.defaultField);
                    
                    this.editField = JSON.parse(JSON.stringify(this.defaultField));
                    this.editField.area_ha_calculated = true;
                    this.selectCultivationSpecies = '';
                }  
                this.setSteps()
            }

        },
        watch: { 
            isVisible: function(newVal) { // watch it
                this.errMessage  = '';
                console.debug('isVisible', this.cmpOptions);
                // this.$refs.form.reset();
                this.editField  = JSON.parse(JSON.stringify(this.defaultField))
                if(newVal){
                    this.load()
                }
                else
                {
                    this.selectCultivationSpecies = '';
                    setTimeout(() => {
                        this.editField = JSON.parse(JSON.stringify(this.defaultField));
                    }, 200);
                    
                }
                console.log('Prop changed: isVisible', newVal, ' | field: ', this.editField)
            },

            cultivation_species_id: function(newVal) { // watch it              
                console.log('Prop changed: cultivation_species_id', newVal)
                // this.setSteps()
            },
        },
        mounted() {
            this.errMessage  = '';
            console.debug('mounted', this.field);
            this.load()

        }

    }

</script>